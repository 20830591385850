// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-bean-bag-packages-js": () => import("/opt/build/repo/src/pages/bean-bag-packages.js" /* webpackChunkName: "component---src-pages-bean-bag-packages-js" */),
  "component---src-pages-sleepover-packages-js": () => import("/opt/build/repo/src/pages/sleepover-packages.js" /* webpackChunkName: "component---src-pages-sleepover-packages-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("/opt/build/repo/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

